.Labels {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 90%;
    max-width: 1000px;
    padding-bottom: 100px;
    flex: 1;
}

.LabelArr {
    margin-top: 80px;
     display: flex;
     flex-direction: column;
     justify-content: flex-start;
     align-items: center;
     width: 100%;
     flex: 1;
 }

 .LabelArrItem {
     display: flex;
     flex-direction: row;
     justify-content: space-between;
     align-items: center;
     width: 100%;
    margin-bottom: 30px;
    border: 1px solid;
    padding-top: 10px;
    padding-bottom: 10px;
 }
 .LabelArrItemLogo {
     display: flex;
     flex-direction: column;
     justify-content: center;
     align-items: center;
     width: 10%;
 }

 .LabelArrItemLogoImg {
     width: 80%;
 }
 .LabelArrItemFirst {
    display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 80%;
 }
  .LabelArrItemPicker {
      display: flex;
    flex-direction: row;
      justify-content: space-between;
      align-items: center;
  }
 .LabelArrItemLast {
     display: flex;
     justify-content: center;
     align-items: center;
     width: 10%;
 }

.LabelsHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

 .LabelArrItemTitle {
     color: black;
    font-weight: 500;
    flex: 1;
 }

  .LabelArrItemText {
    font-size: 110%;
    font-weight: 600;
    color: black;
  }

  .infoTitle {
    position: absolute;
    top: 35.7%;
    transform: rotate(180deg);
    width: 75%;
    font-size: 7px;
    text-align: center;
    color: #519989;
    font-family: 'Work Sans', sans-serif;
  }