.HarvestDialogItemOuter {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    border: 1px solid #44a99a;
    margin-top: 10px;
    color: #44a99a;
}

.HarvestDialogItem {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    color: #44a99a;
}

.HarvestDialogItemBottom {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 90%;
    color: #44a99a;
}

.HarvestDialogItemSlider {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    color: #44a99a;
}