.PlantEnums {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 90%;
    max-width: 1200px;
    padding-bottom: 100px;
    padding-top: 100px;
    flex: 1;
}