.ControlItemMain {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid #44a99a;
    margin-bottom: 15px;
    margin-top: 25px;
}

.ControlItemOuter {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.ControlItemInner {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.ControlItemTitle {
    font-size: 110%;
    font-weight: 700;
    color: #5a5a5a;
    margin-bottom: 4px;
}

.ControlItemText {
    font-size: 80%;
    font-weight: 500;
    color: #797979;
    margin-top: 0;
}