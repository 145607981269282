.appBarFlex {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    flex: 1;
}

.backdropButton {
    position: absolute;
    top: 10px;
    right: 10px;
}

.Header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100vw;
    height: 80px;
    background-color: #44a99a;
    z-index: 1000;
    position: fixed;
    top: 0;
}

.appMenu {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100vw;
    flex: 1;
    padding-top: 0px;
    padding-bottom: 0px;
}

.appMenuClose {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    position: fixed;
    top: 4px;
    z-index: 2000;
}

.HeaderLogo {
    width: 100%;
    height: 94px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.HeaderLogoInner {
    width: 30%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    max-width: 200px;
    min-width: 140px;
    margin-left: calc(5% - 1px);
    cursor: pointer;
}

input,
select {
    -webkit-background-clip: text !important;
    background-clip: text !important;
}

@media (min-width: 500px) {
    .appMenu {
        width: 300px;
    }

    .appMenuClose {
        width: 280px;
        margin-left: 10px;
        margin-right: 10px;
    }
}

@media (min-width: 900px) {
    .HeaderLogoInner {
        margin-left: 18px;
    }
}

.MenuDrawer {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    position: relative;
    padding-top: 100px;
    padding-bottom: 20px;
}

.appMenuBackground {
    width: 12%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
}

.DrawerLogo {
    width: 30%;
    aspect-ratio: 1/1.15;
    margin-bottom: 20px;
}

.appMenuLinksOuter {
    width: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    margin-top: 30px;
}

.appMenuLink {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    text-decoration: none;
    padding: 0;
    margin: 0;
}

.appMenuLinkTitle {
    font-size: 16px;
    font-weight: 500;
    text-align: center;
    text-decoration: none;
    padding: 0;
    margin: 0;
    margin-top: 10px;
    margin-bottom: 10px;
}

.appMenuLinkSub {
    font-size: 13px;
    font-weight: 300;
    text-align: center;
    text-decoration: none;
    padding: 0;
    margin: 0;
    margin-top: 0px;
    margin-bottom: 0px;
}

.appMenuSocials {
    width: 60%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
    margin-bottom: 0px;
}

.appMenuBottomLinks {
    width: 70%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 10px;
    margin-bottom: 10px;
}

@media (min-width: 350px) {
    .appMenuSocials {
        width: 50%;

    }

    .appMenuLinkTitle {
        font-size: 17px;
    }

    .appMenuBottomLinks {
        width: 60%;
    }
        .appMenuLinkSub {
            font-size: 14px;
        }
}

@media (min-width: 500px) {
    .appMenuSocials {
        width: 68%;

    }

    .appMenuBottomLinks {
        width: 68%;
    }
}