.AuthView {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 105vh;
  margin-top: -5vh;
  color: #44a99a;
}

.AuthViewForm {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
  width: 80%;
  max-width: 1000px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.AuthViewInput {
  width: 55%;
  min-width: 200px;
}

.AuthViewButton {
  width: 55%;
  min-width: 200px;
}

.LoginLogo {
  width: 80%;
  max-width: 600px;
}

.OtpOuter {
  width: 55%;
  min-width: 200px;
}

.InfoText {
  color: #363636;
  font-size: 0.9em;
  margin-top: 16px;
  text-align: center;
}

@media only screen and (max-width: 600px) {
  .AuthViewInput {
    width: 100%;
  }
  .AuthViewButton {
    width: 100%;
  }
  .OtpOuter {
    width: 100%;
  }
}
