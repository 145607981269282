.DashBoard {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: calc(100vh - 80px);
  color: #44a99a;
  width: 100vw;
  padding-top: 80px;
}

.DashBoardInner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  color: #44a99a;
  width: 100vw;
  position: relative;
}

.SignoutButton {
  position: absolute !important;
  top: 0;
  right: 0;
}

.DashBoardInnerTabs {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  color: #44a99a;
  width: 100vw;
  flex: 1;
  padding-top: 40px;
}

.DashBoardTabInner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-height: calc(100vh - 300px);
  width: 100%;
  padding-bottom: 100px;
  padding-top: 20px;
}

.DashboardActive {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  flex: 1;
  width: 96%;
}

.DashBoardDocumentsOuter {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  color: #44a99a;
  width: 96%;
  flex: 1;
  margin-bottom: 100px;
}

.DashBoardControlOuter {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  color: #44a99a;
  width: 96%;
  flex: 1;
  margin-bottom: 20px;
  margin-top: 30px;
}