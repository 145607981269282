.App {
  text-align: center;
  display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    width: 100vw;
}

.Start {
  text-align: center;
  display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    color: #44a99a;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.App-header {
  background-color: white;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: black;
}

.StartText {
  margin-top: -5px;
}