.DocumentsUploadOuter {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 30px;
  margin-bottom: 50px;
}

.DocumentsUploadInputOuter {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 99%;
  margin-top: 30px;
}

.DocPicker {
  width: 20%;
}

.DocumentList {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 96%;
  margin-top: 30px;
}

.DocumentListInner {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  flex-wrap: wrap;
  width: 100%;
  margin-top: 10px;
  flex: 1;
}
.DocumentItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 30%;
  margin-top: 0px;
margin-bottom: 30px;
  border: 1px solid #44a99a;
  padding-top: 10px;
  padding-bottom: 20px;
  margin-right: 1.5%;
margin-left: 1.5%;
}

.DocumentListTitle {
  width: 98%;
  text-align: left;
  font-size: 110%;
  font-weight: 600;
}

.DocumentItemInner {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
overflow: hidden;
    width: 94%;
    flex: 1;
}

.DocumentItemTitle {
  text-align: left;
  font-size: 100%;
  font-weight: 600;
  color: #1d1d1d;
  margin-bottom: 8px;
  overflow: hidden;
  width: 94%;
}

.DocumentItemSubtitle {
  text-align: left;
  font-size: 65%;
  font-weight: 600;
  color: #494949;
  font-style: italic;
  margin-top: 0px;
overflow: hidden;
    width: 94%;
}

.DocumentItemIcons {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
  padding-top: 10px;
}

@media only screen and (max-width: 1000px) {
    .DocumentItem {
            width: 46.5%;
        }
}

@media only screen and (max-width: 700px) {
  .DocumentItem {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: flex-start;
    width: 100%;
    padding-top: 10px;
    padding-bottom: 20px;
    margin-right: 0%;
        margin-left: 0%;
  }

  .DocumentItemInner {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding-left: 14px;
    width: calc(73% - 14px);
    overflow: hidden;
  }

  .DocumentItemTitle {
    text-align: left;
    font-size: 100%;
    font-weight: 600;
    color: #1d1d1d;
    margin-bottom: 8px;
  }

  .DocumentItemSubtitle {
    text-align: left;
    font-size: 60%;
    font-weight: 600;
    color: #494949;
    font-style: italic;
    margin-top: 0px;
  }

  .DocumentItemIcons {
    width: calc(23% - 8px);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-right: 8px;
    padding-top: 10px;
  }
}
