.AddPlant {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  width: 96%;
}

.DarkPlants {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 20px;
}

.AddPlantDialog {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 70vw;
  max-width: 100%;
  min-width: 30vw;
}

.AddPlantDialogTop {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  flex-wrap: wrap;
  width: 90%;
  color: #44a99a;
  margin-bottom: 20px;
}

.AddPlantDialogBottom {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 90%;
}

.AddPlantDialogBottomItem {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  border: 1px solid #44a99a;
  margin-top: 10px;
  color: #44a99a;
}

.AddPlantDialogBottomItemText {
  flex: 1;
  margin: 0px;
  padding: 0px;
  font-size: 110%;
  font-weight: 600;
  color: rgb(84, 84, 84);
  margin-top: 30px;
}

.AddPlantDialogBottomItemCount {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  margin-left: 10px;
  color: #565656;
}

.DarkPlantsHeader {
  width: 96%;
  padding-left: 2%;
  padding-right: 2%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  background-color: #44a99a;
  color: white;
}
.PlantListTitle {
  flex: 1;
  padding-top: 10px;
  padding-bottom: 10px;
  color: white;
  font-size: 110%;
  font-weight: 600;
}

.PlantListItemDays {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
  margin-right: 10px;
}

.PlantListItemDaysLarge {
  font-size: 200%;
  margin: 0;
  padding: 0;
}

.PlantListItemDaysSmall {
  font-size: 50%;
  margin: 0;
  padding: 0;
}

.PlantListItemTextOuter {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin: 10px;
  flex: 1;
}
.PlantListSubtitle {
  flex: 1;
  margin: 0px;
  padding: 0px;
  font-size: 70%;
  font-weight: 5;
  color: rgb(84, 84, 84);
  margin-top: 8px;
}
.PlantListItemInfo {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
  margin-right: 10px;
  color: black;
}

.PlantListItemButtons {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin-left: 10px;
  margin-right: 10px;
  color: black;
}

.DarkPlants .AddPlantDialogBottom {
  width: calc(100% - 4px);
}

.DarkPlantAmountText {
    font-size: 300%;
    font-weight: 700;
}